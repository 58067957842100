import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import clsx from 'clsx';
import PropTypes from "prop-types";
import {connect} from 'react-redux';

import { loginUser } from "../../redux/actions/auth/auth";
import { createErrorMessageSelector } from '../../redux/selectors/errors';

import TextField from '@material-ui/core/TextField';
import { InputBase, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 30,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  headerText: {
    color: '#333333',
    fontSize: '1.8rem',
    fontWeight: 600,
  },
  center: {
    alignItems: 'center',
  },
  label: {
    fontWeight: 600,
    fontSize: '1.1rem',
    marginBottom: 10,
  },
  field: {
    background: '#ffffff',
    borderRadius: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  signUpPrompt: {
    display: 'flex',
    fontSize: '1.2rem',
    marginTop: 15,
    justifyContent: 'flex-end',
  },
  signUpButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 10,
  },
  forgot: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  submit: {
    marginTop: 10,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  next: {
    borderRadius: '15px',
    fontWeight: 600,
    fontSize: '1.6rem',
  }
});

class LoginSection extends React.Component {

  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      open: true
    };
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter' && this.state.email != '' && this.state.password != '') {
      e.preventDefault();
      this.onLogin(e);
    }
  }

  onLogin = e => {

    if(this.props.isLoading) return;
    
    e.preventDefault();
    this.setState({open: true})
    
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
  
    this.props.dispatch(loginUser(userData, this.props.history)); 
  };

  handleClickSignUp = () => {
    this.props.history.push('/signup');
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.setState({open: false})
  }

  render() {

    const {classes, invalidLogin} = this.props;
    return(
      <div className={classes.root}>

        {/* <div className={classes.row}>
          <div className={classes.headerText}>Welcome back!</div>
        </div> */}
      
        <div className={classes.row}>
          <div className={classes.label}>Email</div>
          <div className={classes.field} style={{border: this.state.errors.name ? '1px solid red' : '1px solid #ffffff'}}>
            <InputBase
              autoFocus
              id="email"
              name="email"
              type="email"
              fullWidth
              onChange={this.onChange}
              value={this.state.email}
              error={this.state.errors.email}
              className={classnames("", {
                invalid: this.state.errors.email
              })}
              onKeyDown={this.handleKeyDown}
            />
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.label}>Password</div>
          <div className={classes.field} style={{border: this.state.errors.name ? '1px solid red' : '1px solid #ffffff'}}>
            <InputBase
              id="password"
              name="password"
              type="password"
              fullWidth
              onChange={this.onChange}
              value={this.state.password}
              error={this.state.errors.password}
              className={classnames("", {
                invalid: this.state.errors.password
              })}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          <div className={classes.signUpPrompt}>
            <span className={classes.forgot} onClick={this.props.handleForgot}>Forgot password?</span>
          </div>
        </div>

        <div className={clsx(classes.row, classes.submit)}>
          <Button variant="contained" color="primary" className={classes.next} onClick={this.onLogin}>
            Log In
          </Button>
        </div>

        {this.props.errors['LOGIN'] && this.props.errors['LOGIN'].includes('401') && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Incorrect username or password</span>}
          />
        )}
        {this.props.errors['LOGIN'] && this.props.errors['LOGIN'].includes('403') && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Your account has been disabled due to inactivity. If you want to enable it, please contact the support at <a target="_blank" href='mailto:support@hostelworld.com'>support@hostelworld.com</a></span>}
          />
        )}

      </div>
    )
  }
}

const errorSelector = createErrorMessageSelector(['LOGIN']);

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  isLoading: state.loading.LOGIN,
  invalidLogin: errorSelector(state.errors)
});

LoginSection.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(mapStateToProps, null, null, {forwardRef: true})(withRouter(LoginSection)));