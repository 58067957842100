import moment from 'moment';
import { toTitleCase } from '../../../components/utils/wordUtils';
import { RESERVATION_STATUS } from '../../../utils/constants';


// Process reservations to display in report table
// ------------------------------------------------------------------------
const normalReservationProcessing = (reservations, currencyData, rooms, beds) => {
  let dataFormat = [];

  reservations && reservations.length!==0 && reservations.map(item => {

    const bedsInfo = getBedsInfo(item.bedsOccupied, rooms, beds);

    dataFormat.push({
      resvCode: item.resvCode || '',
      name: toTitleCase(item.name),
      checkIn: moment(item.checkIn).format('DD MMM YYYY'),
      checkOut: moment(item.checkOut).format('DD MMM YYYY'),
      duration: Math.round(item.duration),
      status: item.status,
      source: item.bookingSource,
      amount: item.totalAmount,
      balance: item.balance,
      resID: item.resID,
      rawAmount: item.totalAmount,
      rawBalance: item.balance,
      bedsInfo: bedsInfo,
      guestCount: item?.guestCount || 0,
      nonRefundable: item.nonRefundable ? "NRR" : "Standard"
    });
  });
  return dataFormat;
}

const getBedsInfo = (bedsOccupied = [], rooms, beds) =>{
  let formattedData = ``;

  const bedsLength = bedsOccupied.length - 1;

  bedsOccupied.forEach((data, index) =>{

    const roomName = data?.roomID && rooms?.[data.roomID]?.name || '';
    const bedName = data?.bedID && beds?.[data.bedID]?.name || '';

    if(!roomName || !bedName) return;

    formattedData += data.dormOrPrivate == 'dorm' ? `${roomName} ${bedName}` : `${roomName}`;

    if(bedsLength !== index){
      formattedData += `, `;
    }

  });

  return formattedData;
}

//Rooms data formatted for csv display............
const formatRoomsData = (data, roomInfo) =>{
  let formattedData = '';
  data?.forEach((id, index) =>{

    formattedData += `${roomInfo[id]?.name}` || '';

    if(index + 1 == data.length) return;

    formattedData += ", ";
  });

  return `${formattedData}`;
}

const getFormattedDateTime = (dateTime)=>{
    if(!dateTime) return '';
    const formattedDateTime = moment(dateTime).format('DD MMM YYYY HH:mm');
    return formattedDateTime;
}

const formatUpsellList = (data) => {
  if(data.length == 0) return '';
  let result = '';
  data.forEach(item => {
    result += `${item.count}x ${item.upsells.name}: ${item.amount}, `;
  });
  return result;
}

// Process reservations to display in report table
// ------------------------------------------------------------------------
const reservationProcessing = (reservations, currencyData, roomTypes, rooms) => {
  let dataFormat = [];

  reservations && reservations.length!==0 && reservations.map(item => dataFormat.push(
    {
      resvCode: item.resvCode,
      name: toTitleCase(item.name),
      email: item.email,
      phone: item.phone,
      reservationDate: getFormattedDateTime(item.reservationDate),
      checkIn: moment(item.checkIn).format('DD MMM YYYY'),
      checkOut: moment(item.checkOut).format('DD MMM YYYY'),

      confirmedTime: getFormattedDateTime(item.confirmedTime),
      checkedInTime: getFormattedDateTime(item.checkedInTime),
      checkedOutTime: getFormattedDateTime(item.checkedOutTime),
      checkedInByStaff: item.checkedInByStaff,

      duration: Math.round(item.duration),
      source: item.bookingSource,
      status: item.status,
      sourceReservationID: item.sourceReservationID,
      guestCount: item.guestCount,

      accommodationAmount: item.accommodationAmount,
      upsellAmount: item.upsellAmount,
      inclusiveTax: item.inclusiveTax,
      exclusiveTax: item.exclusiveTax,
      totalTaxAmount: item.totalTaxAmount,

      amountPaid: item.amountPaid,
      amount: item.totalAmount,
      balance: item.balance,

      cancellationAt:  getFormattedDateTime(item.cancellationAt),
      cancellationBy: item.cancellationBy,
      cancellationReason: item.cancellationReason,
      commission: item.commission,
      timeOfArrival: item.timeOfArrival,

      roomTypesInfo: formatRoomsData(item.roomTypeIDs, roomTypes),
      roomsInfo: formatRoomsData(item.roomIDs, rooms),

      resID: item.resID,
      nonRefundable: item.nonRefundable ? "YES" : item.nonRefundable === null ? "MIXED" : "NO",

      notes: item.notes,
      guestComments: item.guestComments,
      emails: item.emails,
      addons: formatUpsellList(item.upsellList)
    }
  ));
  return dataFormat;
}

const filteredResProcessing = (reservations, currencyData, status, today, rooms, beds) => {
  const tableData = normalReservationProcessing(reservations, currencyData, rooms, beds);
  let newArray = [];
  if (status!=='') {
    if (status === 'check in') {
      newArray = [...tableData.filter(a => {
        return (
          // In house and checkin date is today
          (a.status===RESERVATION_STATUS.IN_HOUSE && moment().diff(moment(a.checkIn), 'days')===0) ||
          // Confirmed and checkin date is less than equal to today
          (a.status===RESERVATION_STATUS.CONFIRMED && (today ? moment().diff(moment(a.checkIn)) >= 0 : moment().add(1, 'd').diff(moment(a.checkIn)) >= 0))
        )
      })];
    } else if (status === 'check out') {
      newArray = [...tableData.filter(a => {
        return (
          // Checked out and checkout date is today
          (a.status===RESERVATION_STATUS.CHECKED_OUT && moment().diff(moment(a.checkOut), 'days')===0) ||
          // In house and checkout date is less than equal to today
          (a.status===RESERVATION_STATUS.IN_HOUSE  && (today ? moment().diff(moment(a.checkOut)) >= 0 : moment().add(1, 'd').diff(moment(a.checkOut)) >= 0))
        )
      })];
    } else {
      newArray = [...tableData.filter(a => a.status===status)];
    }
     return newArray
  } else return tableData;
}

export { normalReservationProcessing, reservationProcessing, filteredResProcessing};